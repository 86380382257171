import Guest1 from './../images/Guests/1.png';
import Guest2 from './../images/Guests/2.png';
import Guest3 from './../images/Guests/3.png';
import Guest4 from './../images/Guests/4.png';

const Teams = [
   {
       Id: '1',
       tImg: Guest3, 
       name: 'MR. BIJU CHANDRAN',
       title: 'CO FOUNDER,',
       Dis: 'BNI CHENNAI CBD-A'
   },
   {
       Id: '2',
       tImg: Guest4, 
       name: 'MR. MURALI SUNDARAM',
       title: 'AUTHOR & EXECUTIVE DIRECTOR,',
       Dis: 'BNI CHENNAI CBD-A'
   },
   
];

export default Teams;
